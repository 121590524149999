<template>
<div class="bac">
  <div class="main">
    <div class="medium_head">
      <i></i>
      <p>添加作品</p>
    </div>
    <div class="form">
      <div class="name">
        <p>作品名称:</p>
        <input>
      </div>
      <div class="type">
        <p>作品类别:</p>
        <input>
      </div>
      <div class="upload">
        <p>上传作品:</p>
        <div></div>
      </div>
    </div>
    <button>提交</button>
  </div>
</div>
</template>

<script>
export default {
  name: "Add"
}
</script>

<style scoped>
.bac{
  width: 1920px;
  padding-left: 368px;
  padding-top: 75px;
  background-color: #f4f4f4;
}

.main{
  position: relative;
  width: 1462px;
  height: 629px;
  background-color: #ffffff;
  padding-top: 39px;
}

.medium_head{
  width: 1458px;
  height: 36px;
  line-height: 36px;
  margin-left: 43px;
  margin-bottom: 82px;

}

.medium_head i{
  display: block;
  float: left;
  width: 8px;
  height: 36px;
  margin-right: 27px;
  background-color: #ec414d;
}

.medium_head p {
  float: left;
  width: 102px;
  height: 36px;
  line-height: 36px;
  /*margin-top: 10px;*/
  font-size: 24px;
  color: #ec414d;
  font-weight: 700;
}

.form {
  width: 1426px;
  height: 266px;
  padding-left: 128px;
  color: #212121;
}

.form .name{
  /* width: 428px; */
  height: 36px;
  font-size: 20px;
  line-height: 36px;
  margin-bottom: 30px;
}

.form .name p{
  float: left;
  /* width: 86px; */
  height: 36px;
  margin-right: 5px;
}

.form .name input{
  width: 330px;
  height: 36px;
  border: 1px solid #757575;
  border-radius: 3px;
}

.form .type{
  /* width: 428px; */
  height: 36px;
  font-size: 20px;
  line-height: 36px;
  margin-bottom: 30px;
}

.form .type p{
  float: left;
  /* width: 86px; */
  height: 36px;
  margin-right: 5px;
}

.form .type input{
  width: 330px;
  height: 36px;
  border: 1px solid #757575;
  border-radius: 3px;
}

.form .upload{
  /* width: 428px; */
  height: 36px;
  font-size: 20px;
  line-height: 36px;
  margin-bottom: 30px;
}

.form .upload p{
  float: left;
  /* width: 86px; */
  height: 36px;
  margin-right: 5px;
}

.form .upload div{
  float: left;
  width: 182px;
  height: 135px;
  border: 1px solid #757575;
  border-radius: 3px;
}

button{
  position: absolute;
  left: 684px;
  bottom: 92px;
  width: 182px;
  height: 52px;
  background-color: #ec414d;
  color: #ffffff;
  font-size: 24px;
  line-height: 52px;
  border-radius: 5px;
}
</style>